<div layout="row" layout-align="end center" *ngIf="items?.length" class="menu flex-row">
  <ng-container *ngFor="let item of items">
    <ng-container [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="'hidden'"></ng-container>
      <oa-core-share *ngSwitchCase="'share'" [modes]="item.options" [members]="item?.config?.members"
        [entity]="item?.config?.entity" [options]="item?.config?.options"></oa-core-share>
        <span  *ngIf="item.title!=='Export' ">
          <oa-action *ngSwitchDefault view="flat" [item]="item" ></oa-action>
        </span>
    </ng-container>
  </ng-container>
</div>