<ng-container [ngSwitch]="view" *ngIf="device!=='mobile'">
  <div *ngSwitchCase="'side'" class="nav-container">
    <div class="logo-container" (click)="uxService.showSideNav(true)">
      <!-- <img class="logo" src="../../../../assets/images/logo.png"> -->
      <!-- <oa-avatar [value]="logo" [size]="65" [shape]="'square'" [border]="'none'"></oa-avatar> -->
      <!-- <img class="logo" *ngIf="logo" [src]="logo.url"> -->
      <!-- <img class="logo" *ngIf="!logo" src="/assets/images/logo.png" width="90px"> -->

      <i class="logo col-sm-10"></i>
      <br>
    </div>
    <div class="nav-content-div">
      <mat-nav-list dense>
        <ng-container *ngFor="let nav of navs">
          <ng-container *ngIf="auth.hasPermission(nav.permissions) && nav.view !== 'hidden'">
            <div class="list-header controls-row" [ngClass]="{'active': nav.isActive}" [class.extended]="isExpanded"
              (click)="select(nav)" tabindex="-1">
              <div *ngIf="!isExpanded" class="spacer"></div>
              <oa-icon [value]="nav.icon" [class]="isExpanded ? 'md transition' : 'x-md transition'"
                [title]="isExpanded ? undefined : nav.navTitle"></oa-icon>
              <div *ngIf="!isExpanded" class="spacer"></div>

              <div *ngIf="isExpanded" class="title-arrow-div controls-row">
                <span class="nav-item-name">{{nav.navTitle}}</span>

                <div class="leftIcon">
                  <i *ngIf="nav.items.length > 0" class="fa-solid fa-caret-down"></i>
                  <!-- <i class="fa-sharp fa-solid fa-up-from-line"></i> -->
                  <!-- <i class="fa-solid fa-arrow-up-to-line"></i> -->
                  <!-- <mat-icon *ngIf="nav.title">chevron_right</mat-icon> -->
                </div>
              </div>

              <!-- <div *ngIf="nav.icon.url" class="main-menu-icon"
              [style.background.url]="'url('+ nav.icon.activeUrl + ')'"></div> -->
            </div>


            <!-- {{nav.title}} -->
            <!-- <i *ngIf="!nav.current" class="material-icons leftIcon">keyboard_arrow_right</i>
            <i *ngIf="nav.current" class="material-icons leftIcon">keyboard_arrow_down</i> -->
            <div class="list-items">
              <ng-container *ngFor="let item of nav.items">
                <a *ngIf="auth.hasPermission(item.permissions)" class="nav-item-name" [routerLink]="item.routerLink"
                  mat-list-item routerLinkActive="active-link" #rla9="routerLinkActive"
                  [routerLinkActiveOptions]="{ exact: true }">
                  <span>{{item.navTitle}}</span>
                </a>
              </ng-container>

            </div>
          </ng-container>
        </ng-container>
      </mat-nav-list>
      <div class="nav-footer">
        <div class="controls-row">
          <div class="spacer"></div>
          <!-- <oa-icon-toggler type="expand-r" view="icon" [(value)]="isExpanded" (changed)="extend()"></oa-icon-toggler> -->

          <!-- <div *ngIf="isExpanded">Collapse</div> -->
          <!-- <div class="list-header controls-row">
            <mat-icon class="subtle">{{isExpanded ? 'chevron_left' : 'chevron_right'}}</mat-icon>
            <div *ngIf="isExpanded" class="title-arrow-div controls-row">
              <span>Collapse Sidebar</span>
            </div>
          </div>
          <div class="spacer"></div> -->
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'header'">
    <mat-toolbar  class="oa-nav">
      <ng-container *ngFor="let nav of navs">
        <div class="nav-item" [ngClass]="{'active': nav.isActive}" *ngIf="nav.items.length > 0">
          <button mat-button [matMenuTriggerFor]="menu">
           <span class="nav-titles">
            {{ nav.navTitle }}
           <span><mat-icon class="expandIcon">expand_more</mat-icon></span> 
           </span> 
            <!-- <mat-icon >arrow_drop_down</mat-icon> -->
          </button>
        </div>
        <div class="nav-item" [ngClass]="{'active': nav.isActive}" *ngIf="nav.items.length === 0">
          <button mat-button  (click)="select(nav)">
            <span class="nav-titles">
            {{ nav.navTitle }}</span>
          </button>
        </div>
    
        <!-- Dropdown menu -->
        <mat-menu #menu="matMenu" >
          <ng-container *ngFor="let item of nav.items" >
            <a mat-menu-item *ngIf="auth.hasPermission(item.permissions)"
               [routerLink]="item.routerLink"
               routerLinkActive="active-link"
               [routerLinkActiveOptions]="{ exact: true }"
              >
              {{ item.navTitle }}
            </a>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-toolbar>  
  </div>
</ng-container>
<div *ngIf="device==='mobile'">
  <button (click)="dialogRef.close()" class="close"><mat-icon>close</mat-icon></button>
  <mat-toolbar class="side-nav-toolbar">
    <div class="side-nav-container">
      <mat-nav-list class="side-nav-list">
        <ng-container *ngFor="let nav of navs">
          <!-- Only show expansion panel if there are submenu items -->
          <mat-expansion-panel *ngIf="nav.items.length > 0" class="nav-panel" [expanded]="nav.isActive">
            <!-- Main Menu Item with Expand Icon -->
            <mat-expansion-panel-header class="nav-header">
              <button  class="navbutton">
                {{ nav.navTitle }}
              </button>
            </mat-expansion-panel-header>
  
            <!-- Submenu Items -->
            <div *ngIf="nav.items.length > 0" class="submenuNavs">
              <ng-container *ngFor="let item of nav.items">
                <a mat-list-item *ngIf="auth.hasPermission(item.permissions)"
                   [routerLink]="item.routerLink"
                   routerLinkActive="active-link"
                   (click)="close()"
                   [routerLinkActiveOptions]="{ exact: true }"
                   class="submenu-item">
                  {{ item.navTitle }}
                </a>
              </ng-container>
            </div>
          </mat-expansion-panel>
  
          <!-- Main Menu Item without Submenu (No Expand Icon) -->
          <div *ngIf="nav.items.length === 0" class="nav-item1">
            <button class="navbutton" (click)="close(nav)">
              {{ nav.navTitle }}
            </button>
          </div>
        </ng-container>
      </mat-nav-list>
    </div>
  </mat-toolbar>
  
</div>
