import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NavService, UxService } from 'src/app/core/services';
import { Action, Menu } from 'src/app/lib/oa/core/structures';

@Component({
  selector: 'oa-core-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class ContextMenuComponent implements OnInit {

  @Input()
  menu: Menu;

  @Input()
  view: 'bar' | 'inline' = 'bar';

  @Input()
  items: any[];
  screen:string=''

  constructor(
    private uxService: UxService,
    private navService: NavService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.uxService.contextMenuChanges.subscribe(items => {
      this.items = items;
    });
  }

  ngOnInit(): void {
    this.init();  
  }

  init() {
    if (this.menu?.items) {
      this.menu.items.forEach(i => {
        i.type = i.type || 'button';
        i.style = i.style || (i.type === 'icon' ? 'subtle' : 'default filled');
      })
    }
  }
  back() {
    this.navService.back();
  }

  toggle(item: Action) {
    item.value = !item.value;
    item.event(item.value);
  }

  onSelect(item: Action, value: any) {
    item.options.forEach((i) => i.isSelected = false);
    value.isSelected = true;
    item.event(value.code);
  }

  ngOnChanges(changes: SimpleChanges): void{
  this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
    .subscribe((state) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.screen = 'XSmall';
      } else if (state.breakpoints[Breakpoints.Small]) {
        this.screen = 'Small';
      } else if (state.breakpoints[Breakpoints.Medium]) {
        this.screen = 'Medium';
      } else if (state.breakpoints[Breakpoints.Large]) {
        this.screen = 'Large';
      } else if (state.breakpoints[Breakpoints.XLarge]) {
        this.screen = 'XLarge';
      } else {
        this.screen = 'Unknown'; // Fallback
      }
    });

  }

}
